@import "../../style/variables";

.RepeatingFields{
  margin-bottom: 1.8em;

  &>label{
    @include label;
  }

  .items{
    background-color: transparentize($dark-sapphire, $amount: 0.9);
    padding: 0 1.8em 0;

    &:empty{
      &+ .groupActions .wrapper{
        border-top: none !important;
      }
    }

    .item{
      padding: 1.8em 0;
      font-size: .8em;
      border-bottom: 1px solid $dark-sapphire;

      &:last-child{
        border-bottom: none;
      }

      .itemActions{
        margin-top: 1.8em;

        button{
          appearance: none;
          padding: 0;
          border: none;
          font: inherit;
          font-weight: bold;
          text-transform: uppercase;
          color: $dark-sapphire;
          cursor: pointer;
          margin-right: 1.5em;
          background-color: transparent;
        }
      }
    }
  }

  .groupActions{
    background-color: transparentize($dark-sapphire, $amount: 0.9);
    padding: 0 1.8em;

    &:empty{
      display: none;
    }
    
    .wrapper{
      border-top: 1px solid;
      padding: 1.8em 0;

      &:empty{
        display: none;
      }
    }

    button{
      appearance: none;
      padding: 0;
      border: none;
      font: inherit;
      font-weight: bold;
      text-transform: uppercase;
      color: $dark-sapphire;
      cursor: pointer;
      background-color: transparent;
    }
  }
}