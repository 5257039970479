*{
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  margin: 0 !important;
  scroll-behavior: smooth;
}

html{
  scroll-behavior: smooth;
}

#root{
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}