@import "../../style/variables";

.ListBox{
  @include baseField;

  label{
    @include label;
  }
  
  ul{
    padding: 0;
    margin: 5px 0;
    list-style: none;
    border: 1px solid gray;
    max-height: 200px;
    overflow: auto;


    .Option{
      padding: .2em .5em;
      display: flex;
      align-items: center;

      .selectionIndicator{
        width: 1em;
        height: 1em;
        border: 1px solid $dark-sapphire;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: .5em;
        color: transparent;

        svg{
          height: 100%;
          width: 100%;
        }
      }
  
      &.isSelected{
        background-color: $dark-sapphire;
        color: white;
        font-weight: bold;

        .selectionIndicator{
          background-color: white;
          color: $dark-sapphire;
        }
      }
  
      &.isDisabled{
        opacity: .5;
      }
  
      &.isFocusVisible{
        outline: 2px solid orange;
      }
    }

    &.selection-single{
      .Option{
        .selectionIndicator{
          border-radius: .5em;
        }
      }
    }

    &.hideBox{
      border: none;

      .Option{
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;

        &.isSelected{
          background-color: transparent;
          font-weight: unset;
          color: inherit;

          .selectionIndicator{
            color: white;
            background-color: $dark-sapphire;
          }
        }
      }
    }
  }
}