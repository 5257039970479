@import "../../style/variables";

.ListItem{
  appearance: none;
  cursor: pointer;
  padding: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  background-color: transparentize( $dark-sapphire, $amount: 0.9);
  width: 100%;
  font: inherit;
  border: none;
  font-weight: bold;
  color: $dark-sapphire;

  &:hover{
  background-color: transparentize( $dark-sapphire, $amount: 0.8);
  }
}