@import "../../style/variables";

.Base64ImageField{
  @include baseField;

  label{
    @include label;
  }

  .preview{
    img{
      max-width: 200px;
    }
  }
}