@import "../../style/variables";

.RichTextField{
  label{
    @include label;
  }

  .input{
    border: 1px solid gray;
    margin-bottom: 1.8em;
    background-color: white;

    .DraftEditor-root{
      padding: 1em;
    }

    .toolbarWrapper{
      &>div{
        border: none;
        margin: 1px;
        border-top: 1px solid gray;
      }
    }
  }
}