@import "../../style/variables";

.Login{
  background-color: $light-gold;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .top-wrapper{
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .inner-wrapper{
      h1{
        font-family: $titling-font-family;
        font-weight: 900;
        font-size: 36px;
        text-align: center;
        color: $dark-sapphire;
        margin: 0 0 40px;
      }
  
      .methods{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        
        a{
          &, &:link, &:visited, &:active, &:focus, &:hover{
            display: block;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 25px;
            height: 48px;
            margin-bottom: 13px;
            background: $white;
            border: 1px solid #DADADA;
            box-shadow: 0px 4px 8px rgba(253, 103, 26, 0.4);
            border-radius: 24px;
            text-decoration: none;
            width: 100%;
  
            .icon{
              font-size: 0;
              margin-right: 14px;

              svg, img{
                height: 25px;
                width: 25px;
              }
            }
  
            .text{
              font-weight: bold;
              font-size: 14px;
              color: $black
            }
          }
        }
      }
    }
  }

  .bottom-wrapper{
    padding: 40px 60px 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg, img{
      height: 50px;
      width: auto;
    }
  }
}

.ios .Login{
  min-height: -webkit-fill-available;
}