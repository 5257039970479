@import "../../style/variables";

.Modal{
  background-color: rgba(0,0,0,.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 40px;
  overflow: auto;

  .ModalContent{
    max-width: 900px;
    width: 100%;
    padding: 40px;
    overflow: auto;
    background-color: white;
    margin-left: auto;
    margin-right: auto;

    .top{
      display: flex;
      align-items: flex-start;

      h1{
        flex-grow: 1;
        margin-top: 0;
        margin-bottom: 40px;
        color: $dark-sapphire;
      }

      button{
        appearance: none;
        background-color: transparent;
        border: none;
        padding: 10px;
        cursor: pointer;
        color: $dark-sapphire;
      }
    }

    .form{
      .liveLink{
        margin-top: -1.5em;
        margin-bottom: 1.5em;
        display: block;
      }

      hr{
        margin-bottom: 20px;
      }

      .furtherLink{
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 20px;
        display: inline-block;
        color: $dark-sapphire;
      }

      .modalActions{
        border-top: 1px solid gray;
        padding-top: 20px;

        button{
          appearance: none;
          border: none;
          padding: 5px 10px;
          background-color: $dark-sapphire;
          color: white;
          cursor: pointer;
          font-weight: bold;
          text-transform: uppercase;
          margin-right: 10px;
        }
      }
    }
  }
}