@import "./style/variables";

body{
  background-color: $light-gold;
}

a{
  &, &:visited, &:link, &:active, &:focus{
    color: inherit;
  }
}

header#header{
  padding: 20px;
  border-bottom: 1px solid $dark-sapphire;
  display: flex;
  align-items: center;

  .left{
    svg{
      width: 40px;
      height: auto;
    }
  }

  .right{
    text-align: right;
    flex-grow: 1;
    color: $dark-sapphire;

    a{
      margin-left: 20px;
      text-decoration: none;
      font-weight: bold;
    }

    button{
      margin-left: 20px;
      font: inherit;
      font-weight: bold;
      appearance: none;
      cursor: pointer;
      background-color: transparent;
      border: none;
      padding: 0;
      color: inherit;
    }
  }
}

main{
  padding: 40px 20px;
}

.screen{
  h1{
    margin-bottom: 40px;
    margin-top: 0;
    color: $dark-sapphire;
  }  

  .subtitle{
    margin-top: -40px;
    display: block;
    margin-bottom: 40px;
    font-weight: bold;
    color: $dark-sapphire;
  }

  .actions{
    margin-bottom: 40px;

    button{
      appearance: none;
      font: inherit;
      border: none;
      background-color: $dark-sapphire;
      font-weight: bold;
      text-transform: uppercase;
      color: white;
      padding: 10px 20px;
      cursor: pointer;
    }
  }
}