@import "../../style/variables" ;

.Modal{
  .label{
    @include label;
  }

  p{
    margin-top: 0;
  }
}