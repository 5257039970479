.MultiLanguageTextField{
  .item{
    .itemFields{
      display: flex;

      .ListBox{
        margin-right: 3em;
        margin-bottom: 0;
      }

      .TextField{
        flex-grow: 1;
        margin-bottom: 0;
      }
    }
  }
}