@import "../../style/variables";

.MapField{
  margin-bottom: 1.8em;
  
  &>label{
    @include label;
  }

  .map{
    height: 400px !important;
  }

  .fields{
    background-color: transparentize($dark-sapphire, $amount: 0.9);
    padding: 1.8em;
    font-size: .8em;
  }
}