@import "../../style/variables";

.ToggleField{
  margin-bottom: 1.6em;

  label{
    display: flex;
    align-items: center;
  }

  .labelText{
    font-weight: bold;
    color: $dark-sapphire;
    font-size: .9em;
  }

  button{
    appearance: none;
    cursor: pointer;
    border: none;
    height: 20px;
    width: 40px;
    border-radius: 10px;
    margin-right: 10px;
    background-color: transparentize($dark-sapphire, $amount: 0.9);
    display: flex;
    align-items: center;
    padding: 0 4px;

    span{
      height: 16px;
      width: 16px;
      border-radius: 8px;
      display: inline-block;
      background-color: white;
      transition: .2s margin-left, .2s background-color;
      border: 1px solid $dark-sapphire;
    }

    &.selected{
      span{
        background-color: $dark-sapphire;
        margin-left: 14px;
      }
    }
  }
}