.Strings{
  .RepeatingFields > label{
    text-transform: none !important;
  }

  .saveSingle{
    padding: 10px;
    background-color: red;
    margin-top: -30px;

    button{
      appearance: none;
      cursor: pointer;
      border: 2px solid;
      color: white;
      font-weight: bold;
      font-family: inherit;
      background-color: transparent;
    }
  }
}